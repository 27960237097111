<script setup>

import { Head, useForm } from '@inertiajs/vue3'

import Layout from '../Layout.vue'
import Editor from 'primevue/editor';
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import '@vueup/vue-quill/dist/vue-quill.bubble.css'
const form = useForm({
    category: '',
    main_category: '',
    short_name: '',
    subject: '',
    description: '',
    email_body: '',
    active: true,
    exit: false,
})

const handleInput = (id, key) => {
    form[key] = id;
}

const submitForm = (exit) => {
    form.exit = !!exit;
    form
        .post('/admin/follow_up/store' , {
            preserveScroll: true,
            onSuccess: () => form.reset(),
        })
}

</script>

<template>

    <Head title="Add Email Template" />

    <Layout>
        <template v-slot:breadcrumb>
            <ol class="breadcrumb breadcrumb-alt">
                <li aria-current="page" class="breadcrumb-item">
                    <a class="link-fx" href="/admin/follow_up">Email templates</a>
                </li>
                <li class="breadcrumb-item">
                    Add
                </li>
            </ol>
        </template>

        <template v-slot:title>Email Templates</template>

        <template v-slot:content>
            <div class="row justify-content-center">
                <div class="col-xxl-8">
                    <div class="block block-rounded">
                        <!-- Form Start -->
                        <form>
                            <div class="block-header block-header-default">
                                <h3 class="block-title">Add Template</h3>
                            </div>
                            <div class="block-content block-content-full">
                                <div class="row g-12">
                                    <div class="col-12 col-sm-12">
                                        <v-select
                                            label="Category"
                                            v-model="form.main_category"
                                            required
                                            placeholder="Select email category"
                                            :error-messages="form.errors.main_category"
                                            clearable
                                            :items="['Reached', 'Not Reached']"
                                        ></v-select>
                                    </div>
                                    <div class="col-12 col-sm-12">
                                        <v-select
                                            label="Sub-category"
                                            v-model="form.category"
                                            required
                                            placeholder="Select email sub-category"
                                            :error-messages="form.errors.category"
                                            clearable
                                            :items="['Introduction', 'No Response', 'Follow-Up', 'Thank You', 'Last chance', 'Before trip', 'Post Trip', 'Reminders', 'Notifications', 'Usefull', 'Holidays', 'Canceled Trip', 'SMS & WhatsApp']"
                                        ></v-select>
                                    </div>

                                    <div class="col-12 col-sm-12">
                                        <v-text-field
                                            label="Short Name"
                                            v-model="form.short_name"
                                            required
                                            placeholder="Enter short name"
                                            :error-messages="form.errors.short_name"
                                            clearable
                                        ></v-text-field>
                                    </div>

                                    <div class="col-12 col-sm-12">
                                        <v-text-field
                                            label="Description"
                                            v-model="form.description"
                                            placeholder="Enter description"
                                            :error-messages="form.errors.description"
                                            clearable
                                            required
                                        ></v-text-field>
                                    </div>
                                    <div class="col-12 col-sm-12">
                                        <v-text-field
                                            label="Subject"
                                            v-model="form.subject"
                                            required
                                            placeholder="Enter short name"
                                            :error-messages="form.errors.subject"
                                            clearable
                                        ></v-text-field>
                                    </div>
                                    <div class="col-sm-12 col-12">
                                        <Editor v-model="form.email_body"  editorStyle="height: 320px" />
                                    </div>
                                    <div class="col-12 col-sm-12">
                                         <v-switch label="Is Active?"
                                                  v-model="form.active"
                                        ></v-switch>
                                    </div>

                                    <div class="col-sm-12 col-12">
                                        <v-card title="Variables which we can use">
                                            <v-card-text style="padding: 10px">
                                                <table style="width: 100%;">
                                                    <thead>
                                                    <tr>
                                                        <th style="padding-left: 15px">Client:</th>
                                                        <th style="padding-left: 15px">Request:</th>
                                                        <th style="padding-left: 15px">Agent:</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>
                                                            <ol>
                                                                <li>$customerFirstName</li>
                                                                <li>$customerLastName</li>
                                                                <li>$customerFullName</li>
                                                                <li>$customerTimeOfTheDay</li>
                                                                <li>$lastQuotesSentDaysAgo</li>
                                                                <li>$totalQuotesSent</li>
                                                            </ol>
                                                        </td>
                                                        <td>
                                                            <ol>
                                                                <li>$departureDate</li>
                                                                <li>$returnDate</li>
                                                                <li>$departureCity</li>
                                                                <li>$returnCity</li>
                                                                <li>$numberOfAdults</li>
                                                                <li>$numberOfChildren</li>
                                                                <li>$numberOfInfants</li>
                                                            </ol>
                                                        </td>
                                                        <td>
                                                            <ol>
                                                                <li>$agentFirstName</li>
                                                                <li>$agentLastName</li>
                                                                <li>$agentFullName</li>
                                                                <li>$agentEmail</li>
                                                                <li>$agentPhone</li>
                                                            </ol>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </v-card-text>
                                        </v-card>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <!-- Form End -->
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:footer-buttons>
            <button type="button" class="btn btn-alt-secondary mb-3 mb-sm-0 me-sm-3" @click="submitForm(true)">Save and exit</button>
            <button type="button" class="btn btn-primary" @click="submitForm(false)">Save</button>
        </template>

    </Layout>

</template>

<style scoped>
.two-columns {
    -moz-column-count: 2;
    -moz-column-gap: 20px;
    -webkit-column-count: 2;
    -webkit-column-gap: 20px;
    column-count: 2;
    column-gap: 20px;
}

table {
    width: 100%;
    border-collapse: collapse; /* Убирает лишние отступы между ячейками таблицы */
}

td, th {
    padding: 5px; /* Унифицированный отступ для заголовков и данных */
    vertical-align: top; /* Выравнивание текста по верхней части ячейки */
}

ol {
    margin: 0; /* Убирает внешние отступы */
    padding-left: 20px; /* Минимальный внутренний отступ для списков */
}
</style>
