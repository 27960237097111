<template>
    <div class="modal fade"
         :class="{'show': visibleModal}"
         @click.prevent="$emit('hide-modal')"
         id="exampleModal"
         tabindex="-1"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" @click.stop>
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">{{headerName}}</h1>
                    <button type="button" class="btn-close" @click="$emit('hide-modal')"></button>
                </div>
                <div class="modal-body">
                    <slot name="modal-body"></slot>
                </div>
                <div class="modal-footer" style="display:flex; flex-direction: row; justify-content: space-between">
                    <div style="width: 70%; display: flex; align-items: center;">
                        <slot name="modal-footer-left"></slot>
                    </div>
                    <div>
                        <button type="button" style="margin-right: 10px" class="btn btn-outline-dark" @click="$emit('hide-modal')">Close</button>
                        <slot name="modal-footer"></slot>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="modal-backdrop fade "
         v-if="visibleModal"
         :class="{'show': visibleModal}"
    ></div>

</template>

<script>
export default {
    name: "Modal",
    props: {
        visibleModal: Boolean,
        headerName: {default : 'Add', type: String},
    },
    emits: ['hide-modal'],

}
</script>

<style>

</style>
