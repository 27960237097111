<script setup>

import {Head, useForm} from '@inertiajs/vue3'

import Layout from '../Layout.vue'
import InputSimple from "../../components/UI/InputSimple.vue";

const props = defineProps({
    resource: Object,
    actions: {
        type: Array,
        default: () => []
    },
})

const resource = props.resource;

const form = useForm({
    t1_name: resource.t1_name,
    t1_percent: resource.t1_percent,
    t1_price: resource.t1_price,
    t2_name: resource.t2_name,
    t2_percent: resource.t2_percent,
    t2_price: resource.t2_price,
    t3_name: resource.t3_name,
    t3_percent: resource.t3_percent,
    t4_price: resource.t4_price,
    t4_name: resource.t4_name,
    t4_percent: resource.t4_percent,
    t3_price: resource.t3_price,
    cc_fee_percent: resource.cc_fee_percent,
    cc_fee: resource.cc_fee,
    processing_fee: resource.processing_fee
})

const submitForm = () => {
    form
        .put('/admin/variables/update', {
            preserveScroll: true,
            // preserveState: false,
        })
}

</script>

<template>

    <Head title="Variables"/>

    <Layout>

        <template v-slot:title>Variables</template>

        <template v-slot:content>
            <div class="row justify-content-center">
                <div class="col-xxl-8">
                    <div class="block block-rounded">
                        <!-- Form Start -->
                        <form>
                            <div class="block-header block-header-default">
                                <h3 class="block-title">General settings</h3>
                            </div>
                            <div class="block-content block-content-full">
                                <h5>Travel Protection cost</h5>
                                <div class="row g-12 align-items-center">
                                    <div class="col-12 col-sm-6 col-lg-2">
                                        <span class="fw-semibold">Tier 1</span>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-10">
                                        <div class="row g-12">
                                            <div class="col-12 col-sm-6 col-lg-4">
                                                <input-simple
                                                    label="Tier name"
                                                    name="t1_name"
                                                    required
                                                    placeholder="Enter tier name"
                                                    v-model="form.t1_name"
                                                    :hint="form.errors.t1_name"
                                                ></input-simple>
                                            </div>
                                            <div class="col-12 col-sm-6 col-lg-4">
                                                <input-simple
                                                    label="Tier %"
                                                    name="t1_percent"
                                                    placeholder="Enter tier %"
                                                    v-model="form.t1_percent"
                                                    :hint="form.errors.t1_percent"
                                                ></input-simple>
                                            </div>
                                            <div class="col-12 col-sm-6 col-lg-4">
                                                <input-simple
                                                    label="Minimum price $"
                                                    name="t1_price"
                                                    placeholder="Enter minimum price"
                                                    v-model="form.t1_price"
                                                    :hint="form.errors.t1_price"
                                                ></input-simple>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row g-12 align-items-center">
                                    <div class="col-12 col-sm-6 col-lg-2">
                                        <span class="fw-semibold">Tier 2</span>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-10">
                                        <div class="row g-12">
                                            <div class="col-12 col-sm-6 col-lg-4">
                                                <input-simple
                                                    label="Tier name"
                                                    name="t2_name"
                                                    required
                                                    placeholder="Enter tier name"
                                                    v-model="form.t2_name"
                                                    :hint="form.errors.t2_name"
                                                ></input-simple>
                                            </div>
                                            <div class="col-12 col-sm-6 col-lg-4">
                                                <input-simple
                                                    label="Tier %"
                                                    name="t2_percent"
                                                    placeholder="Enter tier %"
                                                    v-model="form.t2_percent"
                                                    :hint="form.errors.t2_percent"
                                                ></input-simple>
                                            </div>
                                            <div class="col-12 col-sm-6 col-lg-4">
                                                <input-simple
                                                    label="Minimum price $"
                                                    name="t2_price"
                                                    placeholder="Enter minimum price"
                                                    v-model="form.t2_price"
                                                    :hint="form.errors.t2_price"
                                                ></input-simple>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="row g-12 align-items-center">
                                    <div class="col-12 col-sm-6 col-lg-2">
                                        <span class="fw-semibold">Tier 3</span>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-10">
                                        <div class="row g-12">
                                            <div class="col-12 col-sm-6 col-lg-4">
                                                <input-simple
                                                    label="Tier name"
                                                    name="t3_name"
                                                    required
                                                    placeholder="Enter tier name"
                                                    v-model="form.t3_name"
                                                    :hint="form.errors.t3_name"
                                                ></input-simple>
                                            </div>
                                            <div class="col-12 col-sm-6 col-lg-4">
                                                <input-simple
                                                    label="Tier %"
                                                    name="t3_percent"
                                                    placeholder="Enter tier %"
                                                    v-model="form.t3_percent"
                                                    :hint="form.errors.t3_percent"
                                                ></input-simple>
                                            </div>
                                            <div class="col-12 col-sm-6 col-lg-4">
                                                <input-simple
                                                    label="Minimum price $"
                                                    name="t3_price"
                                                    placeholder="Enter minimum price"
                                                    v-model="form.t3_price"
                                                    :hint="form.errors.t3_price"
                                                ></input-simple>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row g-12 align-items-center">
                                    <div class="col-12 col-sm-6 col-lg-2">
                                        <span class="fw-semibold">Tier 3 + CFAR</span>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-10">
                                        <div class="row g-12">
                                            <div class="col-12 col-sm-6 col-lg-4">
                                                <input-simple
                                                    label="Tier name"
                                                    name="t3_name"
                                                    required
                                                    placeholder="Enter tier name"
                                                    v-model="form.t4_name"
                                                    :hint="form.errors.t4_name"
                                                ></input-simple>
                                            </div>
                                            <div class="col-12 col-sm-6 col-lg-4">
                                                <input-simple
                                                    label="Tier %"
                                                    name="t3_percent"
                                                    placeholder="Enter tier %"
                                                    v-model="form.t4_percent"
                                                    :hint="form.errors.t4_percent"
                                                ></input-simple>
                                            </div>
                                            <div class="col-12 col-sm-6 col-lg-4">
                                                <input-simple
                                                    label="Minimum price $"
                                                    name="t3_price"
                                                    placeholder="Enter minimum price"
                                                    v-model="form.t4_price"
                                                    :hint="form.errors.t4_price"
                                                ></input-simple>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <h5 style="margin-top: 40px">Other</h5>

                                <div class="row g-12 align-items-center">
                                    <div class="col-12 col-sm-6 col-lg-2">
                                        <span class="fw-semibold">Processing </span>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-10">
                                        <div class="row g-12">
                                            <div class="col-12 col-sm-6 col-lg-4">
                                                <input-simple
                                                    label="Processing Fee $"
                                                    name="processing_fee"
                                                    required
                                                    placeholder="Enter tier name"
                                                    v-model="form.processing_fee"
                                                    :hint="form.errors.processing_fee"
                                                ></input-simple>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="row g-12 align-items-center">
                                    <div class="col-12 col-sm-6 col-lg-2">
                                        <span class="fw-semibold">Credit Card </span>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-10">
                                        <div class="row g-12">
                                            <div class="col-12 col-sm-6 col-lg-4">
                                                <input-simple
                                                    label="Credit Card Fee Percent (0-100%)"
                                                    name="cc_fee_percent"
                                                    required
                                                    placeholder="Enter tier name"
                                                    v-model="form.cc_fee_percent"
                                                    :hint="form.errors.cc_fee_percent"
                                                ></input-simple>
                                            </div>
                                            <div class="col-12 col-sm-6 col-lg-4">
                                                <input-simple
                                                    label="Credit Card Fee $"
                                                    name="cc_fee"
                                                    required
                                                    placeholder="Enter tier name"
                                                    v-model="form.cc_fee"
                                                    :hint="form.errors.cc_fee"
                                                ></input-simple>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>
                        <!-- Form End -->
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:footer-buttons v-if="actions.includes('Update')">
            <button type="button"
                    class="btn btn-primary ms-sm-3" @click="submitForm">Save</button>
        </template>

    </Layout>

</template>

<style>

</style>
